<template>
  <header class="header-reduced">
    <div class="header-reduced__content container">
      <div class="header-reduced__logo-wrapper">
        <div class="header-reduced__logo">
          <NuxtLink to="/">
            <spar-adaptive-image
              class="header-reduced__logo-img"
              data-tosca="header-reduced-logo"
              :image="logo"
            />
          </NuxtLink>
        </div>
      </div>

      <spar-button
        icon="arrow-bottom"
        class="help-overlay__btn btn--secondary btn--icon-left"
        :class="[{ 'help-overlay__btn--rotate': getHelp }]"
        @click="getHelp = !getHelp"
        >{{ helpButton }}</spar-button
      >

      <div v-if="getHelp" class="help-overlay">
        <spar-help-overlay
          v-model="getHelp"
          :heading="heading"
          :info-text="infoText"
          :opening-hours1="openingHours1"
          :opening-hours2="openingHours2"
          :telefon="telefon"
          :email="email"
        >
        </spar-help-overlay>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import SparHelpOverlay from "~/components/sections/SparHeaderReduced/SparHelpOverlay/SparHelpOverlay.vue";
import { SparButton, SparAdaptiveImage, type SparDamAsset } from "~/components/shared";
import type { SparHeaderReducedProps } from "./SparHeaderReduced.types";

defineProps({
  logo: {
    type: Object as PropType<SparDamAsset>,
    required: true,
  },
  modelValue: {
    type: Boolean,
  },
  heading: {
    type: String,
    default: "",
  },
  infoText: {
    type: String,
    default: "",
  },
  openingHours1: {
    type: String,
    default: "",
  },
  openingHours2: {
    type: String,
    default: "",
  },
  telefon: {
    type: String,
    default: "",
  },
  email: {
    type: String,
    default: "",
  },
  helpButton: {
    type: String,
    default: "",
  },
}) as SparHeaderReducedProps;

const getHelp = ref(false);
</script>

<style lang="scss">
@use "./SparHeaderReduced.scss";
</style>
