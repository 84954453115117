<template>
  <spar-link
    icon="close"
    icon-position="right"
    :aria-label="$t('global.close')"
    class="help-overlay__close"
    :variant="LinkVariant.linkIcon"
    @click="() => emit('update:modelValue', false)"
  />
  <div>
    <div class="help-overlay__contact-information">
      <div class="help-overlay__heading">{{ heading }}</div>
      <div class="help-overlay__infotext">{{ infoText }}</div>
      <div class="help-overlay__opening-hours1">{{ openingHours1 }}</div>
      <div class="help-overlay__opening-hours2">{{ openingHours2 }}</div>
      <div class="help-overlay__phone-text">
        <spar-link
          icon="phone"
          icon-position="left"
          class="help-overlay__contact-text"
          :link="`tel:${telefon}`"
          :label="telefon"
          :variant="LinkVariant.linkIcon"
        >
        </spar-link>
      </div>
      <div class="help-overlay__mail-text">
        <spar-link
          icon="mail"
          icon-position="left"
          class="help-overlay__contact-text"
          :link="`mailto:${email}`"
          :label="email"
          :variant="LinkVariant.linkIcon"
        ></spar-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LinkVariant, SparLink } from "~/components/shared";
import useI18n from "~/composables/i18n/useI18n";

const { $t } = useI18n(); // Explicit Import for Storybook

defineProps({
  modelValue: {
    type: Boolean,
  },
  heading: {
    type: String,
    default: "",
  },
  infoText: {
    type: String,
    default: "",
  },
  openingHours1: {
    type: String,
    default: "",
  },
  openingHours2: {
    type: String,
    default: "",
  },
  telefon: {
    type: String,
    default: "",
  },
  email: {
    type: String,
    default: "",
  },
  helpButton: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);
</script>

<style lang="scss">
@use "./SparHelpOverlay.scss";
</style>
