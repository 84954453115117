<template>
  <div class="layout--reduced content">
    <spar-header-reduced v-if="pageHeaderReduced" v-bind="pageHeaderReduced" />
    <spar-notification />
    <slot />
    <spar-footer v-if="pageFooter" v-bind="pageFooter"></spar-footer>
  </div>
</template>

<script lang="ts" setup>
import { SparFooter, SparHeaderReduced } from "~/components/sections";
import { SparNotification } from "~/components/shared";
import { useLayout } from "~/composables/layout/useLayout";

const { getPageHeaderReduced, getPageFooter } = useLayout();
const pageHeaderReduced = await getPageHeaderReduced();
const pageFooter = await getPageFooter();
</script>

<style lang="scss">
@use "./reduced.scss";
</style>
